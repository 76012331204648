import React, { useState } from 'react';
import { Container, NavLink, Form, InputGroup, FormControl, Modal } from 'react-bootstrap';

import "../../../Assets/css/home.css";
import { Button } from '@material-ui/core';
import { base_url, client_name, currencycode, postHeader } from '../../../Helpers/request';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Loader from '../../../Components/Loader';

const Newsletter = () => {
  const history = useHistory();
  const [newsletteremail, setNewsletteremail] = useState("")
  const [loader,setLoader] = useState(false)
  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true)
    const data = { currency_code : currencycode,
      email: newsletteremail
    }
    axios.post(base_url + '/common/subscribe_newsletter', data, {
      headers: postHeader
    })
      .then(res => {
        if (res.data.status == 1) {
          setLoader(false)
          setNewsletteremail('');
          return swal(res.data.message, "", "success");
        } else {
          setLoader(false)
          return swal(res.data.message, "", "error");
        }
      })
      .catch((error) => {
        setLoader(false)
        console.log(error);
      });
  }
  return (
    <>
      <div className="rcs_newsletter">
        <Container>
          <div className="rcs_news_content">
            <h2>Subscribe To Our Newsletter</h2>
            <p>Sign-up for exclusive news, offers and monthly savings.</p>
            <div className="gs_newsletter_input_section">
              <Form className="gs_news_form w-100" onSubmit={newsletter}>
                <InputGroup className="">
                  <FormControl
                    placeholder="Email Address..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    type="email"
                    value={newsletteremail}
                    required
                    onChange={(e) => setNewsletteremail(e.target.value)}
                  />
                  <Button variant="outline-secondary" type="submit" id="button-addon2">
                    Subscribe
                  </Button>
                </InputGroup>
              </Form>
            </div>
          </div>
        </Container>
      </div>
      <Modal show={loader}>
        <Loader/>
      </Modal>
    </>
  )
}

export default Newsletter;