import React from 'react';
import { Container, Row, Col, NavLink, Button } from 'react-bootstrap';
import collection1 from "./assets/images/collection/1.jpg";
import collection2 from "./assets/images/collection/2.jpg";
import collection3 from "./assets/images/collection/3.jpg";
import collection4 from "./assets/images/collection/4.jpg";
import collection5 from "./assets/images/collection/5.jpg";
import icnLeft from "../../../Assets/images/icon/Ex-right-icn.webp";

import Slider from "react-slick";
import { Link } from 'react-router-dom';
// import { LazyLoadImage } from "react-lazy-load-image-component";

const ExploreCollection = () => {
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className="rcs_collection_section1 section-space">
            <Container fluid>
                <div className="rcs_collection1_title rcs__mobile_collection_title text-center mb-0">
                    <h2>Shop Our Diamond Jewelry Online </h2>
                    {/* <p className='w-40 mx-auto'>Dive into luxury with our exclusive diamond jewelry collection. Discover timeless elegance and exquisite craftsmanship in every piece. Elevate your style with our stunning pieces today.</p> */}
                </div>
                <Row>
                    <Col lg={12}>
                        <div className="rcs_customer_review_slider ej_jews">
                            <Slider className="rcs_customer_say_inner" {...settings2}>
                                <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                    <div className="rcs_collection1_box_img">
                                        <img src={collection1} alt="shop-ring" title="shop-ring" />
                                    </div>
                                    <div className="rcs_collection1_box_btn ">
                                        <h3>Rings</h3>
                                        <p> That Will Mark Your Life’s Precious Moments</p>
                                        <Link to="/jewelry/fashion-rings">Explore more</Link>
                                    </div>
                                </div>

                                <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                    <div className="rcs_collection1_box_img">
                                        <img src={collection2} alt="shop-bracelet" title="shop-bracelet" />
                                    </div>
                                    <div className="rcs_collection1_box_btn">
                                        <h3>Bracelets</h3>
                                        <p>Wrap Your Wrist In Timeless Beauty
                                        </p>
                                        <Link to="/jewelry/bracelets">Explore more</Link>
                                    </div>
                                </div>

                                <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                    <div className="rcs_collection1_box_img">
                                        <img src={collection3} alt="shop-pendants" title="shop-pendants" />
                                    </div>
                                    <div className="rcs_collection1_box_btn">
                                        <h3>Pendants</h3>
                                        <p>That Will Add A Charm To Your Look</p>
                                        <Link to="/jewelry/pendants">Explore more</Link>
                                    </div>
                                </div>

                                <div className="rcs_collection1_box rcs_mobile_collection1_box">
                                    <div className="rcs_collection1_box_img">
                                        <img src={collection4} alt="shop-necklace" title="shop-necklace" />
                                    </div>
                                    <div className="rcs_collection1_box_btn">
                                        <h3>Necklaces</h3>
                                        <p>That Shine With Elegance And Style</p>
                                        <Link to="/jewelry/necklaces">Explore more</Link>
                                    </div>
                                </div>

                                <div className="rcs_collection1_box rcs_mobile_collection1_box" >
                                    <div className="rcs_collection1_box_img">
                                        <img src={collection5} alt="shop-earring" title="shop-earring" />
                                    </div>
                                    <div className="rcs_collection1_box_btn">
                                        <h3>Earrings</h3>
                                        <p>To Give Your Earlobes An Exquisite Sparkle</p>
                                        <Link to="/jewelry/earrings">Explore more</Link>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
export default ExploreCollection;
