import React from 'react';
import { Col, Image, NavLink, Row } from 'react-bootstrap';

import Slider from "react-slick";
import StarIcon from '@mui/icons-material/Star';
import google_icon from "../../../Assets/images/google_icon.svg";
import rcs_quote_new from "../../../Assets/images/home/quote.png";
import yelp_icon from "../../../Assets/images/home/yelp.png";
import dummy_user from "../../../Assets/images/home/dummy_user.png";
import { useHistory } from 'react-router-dom';
import { client_name } from '../../../Helpers/request';
import Google from "./assets/images/google.png";
import EJ from "./assets/images/ej-logo-black.png";
import Yelp from "./assets/images/yelp-reviews.png";
import Container from './Container';


const Testimonial = () => {
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    accessibility: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
  };

  return (
    <div className="rcs_testimonials">
      <Container>
          <h5 className="text-center">Our Customer Feedback</h5>
          <p className='subtitle mb-5'>Don’t take our word for it. Trust our customers</p>

            <Row>
                <Col lg={4} className='testimonial-wrapper'>
                    <div className='testimonial-block'>
                        <Image src={Google} alt='' />

                        <Slider className="rcs_testimonial_inner" {...settings2}>
                            <div className="rcs_testi_item">
                                <h4>Ryan Hamm</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>Upgraded my wife's wedding ring recently.  Sam and Connie were great.  They were thorough, patient, and honest.   They took their time to explain the differences between the diamonds.  Had a great sel <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Dominic DeMicco</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>I was referred to European Jewelry by a couple coworkers that had been customers and I was completely blown away. Opting for a custom engagement ring, Sam was able to procure 8 diamonds in a week's no <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Jonathan Eassa</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>Sam and the rest of the staff at European Jewelry were great to work with! Sam was very knowledgeable, patient, and helpful throughout the engagement ring selection process. The custom-made ring not o <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Brian Augustine</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>I recently took a diamond earring that meant a lot to my wife(The other earring was lost) and had it made into a beautiful necklace by European Jewelry. Your team helped me design it, and she loves it <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Erika Foltys</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>My engagement ring is PERFECT! We are so happy with the ring and the service we received using European Jewelry. I would highly recommend. <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                        </Slider>
                    </div>
                </Col>
                
                <Col lg={4} className='testimonial-wrapper'>
                    <div className='testimonial-block'>
                        <Image src={EJ} alt='' />

                        <Slider className="rcs_testimonial_inner" {...settings2}>
                            <div className="rcs_testi_item">
                                <h4>Ryan Hamm</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>Upgraded my wife's wedding ring recently.  Sam and Connie were great.  They were thorough, patient, and honest.   They took their time to explain the differences between the diamonds.  Had a great sel <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Dominic DeMicco</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>I was referred to European Jewelry by a couple coworkers that had been customers and I was completely blown away. Opting for a custom engagement ring, Sam was able to procure 8 diamonds in a week's no <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Jonathan Eassa</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>Sam and the rest of the staff at European Jewelry were great to work with! Sam was very knowledgeable, patient, and helpful throughout the engagement ring selection process. The custom-made ring not o <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Brian Augustine</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>I recently took a diamond earring that meant a lot to my wife(The other earring was lost) and had it made into a beautiful necklace by European Jewelry. Your team helped me design it, and she loves it <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Erika Foltys</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>My engagement ring is PERFECT! We are so happy with the ring and the service we received using European Jewelry. I would highly recommend. <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                        </Slider>
                    </div>
                </Col>

                <Col lg={4} className='testimonial-wrapper'>
                    <div className='testimonial-block'>
                        <Image src={Yelp} alt='' />

                        <Slider className="rcs_testimonial_inner" {...settings2}>
                            <div className="rcs_testi_item">
                                <h4>Ryan Hamm</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>Upgraded my wife's wedding ring recently.  Sam and Connie were great.  They were thorough, patient, and honest.   They took their time to explain the differences between the diamonds.  Had a great sel <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Dominic DeMicco</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>I was referred to European Jewelry by a couple coworkers that had been customers and I was completely blown away. Opting for a custom engagement ring, Sam was able to procure 8 diamonds in a week's no <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Jonathan Eassa</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>Sam and the rest of the staff at European Jewelry were great to work with! Sam was very knowledgeable, patient, and helpful throughout the engagement ring selection process. The custom-made ring not o <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Brian Augustine</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>I recently took a diamond earring that meant a lot to my wife(The other earring was lost) and had it made into a beautiful necklace by European Jewelry. Your team helped me design it, and she loves it <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                            <div className="rcs_testi_item">
                                <h4>Erika Foltys</h4>
                                {/* <span>Lead Intranet Technician</span> */}
                                <div className="rcs_test_star my-1">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div> 
                                <p className='my-2'>My engagement ring is PERFECT! We are so happy with the ring and the service we received using European Jewelry. I would highly recommend. <a className='text-dark font-weight-bold' href="https://www.google.com/search?q=european+jewelry+inc&amp;rlz=1C1CHZL_enIN878IN878&amp;oq=european+jewelry+inc&amp;aqs=chrome.0.69i59j35i39j0l3j69i60l3.7462j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x880fba01a0ff1ecb:0xfa25db93c9e1fea2,1,,," target="_blank" class="review_read_more">Read more...</a></p>
                            </div>
                        </Slider>
                    </div>
                </Col>
            </Row>
      </Container>
    </div>
  )
}

export default Testimonial;