import React from 'react';
import Container from './Container';

const Insta = () => {
    return (
        <div className='insta_section'>
            <Container>
                <div className="rcs_news_content1 text-center w-100 mb-3" >
                    <h2 className='text-center mt-0'>Get A Tour Of Our Social Media</h2>
                    <p>Follow <a target='_blank' href="https://www.instagram.com/europeanjewelryinc/?hl=en">@europeanjewelryinc</a> on Instagram to get inspired</p>
                </div>
            </Container>
            <iframe src="https://instagram.demobw.live/europeanjewelryinc/" frameborder="0" width="100%" class="insta-iframe"></iframe>
        </div>
    )
}

export default Insta;