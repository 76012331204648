import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { NavLink, useHistory } from 'react-router-dom';
import "../../../Assets/css/mjstatic.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { base_url } from '../../../Helpers/request';
import { useSelector } from 'react-redux';
import Container from './Container';

const FAQ = () => {
    const history = useHistory();
    const metaDetails = useSelector(state => state.persistedReducer.meta.meta)
    const [expanded, setExpanded] = React.useState(true);
    const [faqdata, setFaqdata] = React.useState([]);
   
    return (
        <div className="faq_section mt-3">
            <Container>
                <div className="rcs_mobile_menu mt-3 mb-5 w-100">
                    <h2 className='text-center'>frequently asked question</h2>
                    <div className='rcs_faq_accordion'>
                    {metaDetails.faq?.map((val,index) =>
                        index == 0 ?
                        <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)}> 
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className='m-0'>{val.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className='m-0'>
                                    <p className='m-0' dangerouslySetInnerHTML={{ __html: val.description }} />
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        :
                        <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={"panel1a-content" + index}
                            id={"panel1a-header" + index}
                        >
                            <Typography className='m-0'>{val.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='m-0'>
                                <p className='m-0' dangerouslySetInnerHTML={{ __html: val.description }} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default FAQ;