import React from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import roundImg from "../../../Assets/images/home/diamond-shapes-img/Round.webp";
import princessImg from "../../../Assets/images/home/diamond-shapes-img/Princess.webp";
import cushionImg from "../../../Assets/images/home/diamond-shapes-img/Cushion.webp";
import emeraldImg from "../../../Assets/images/home/diamond-shapes-img/Emerald.webp";
import ovalImg from "../../../Assets/images/home/diamond-shapes-img/Oval.webp";
import radiantImg from "../../../Assets/images/home/diamond-shapes-img/Radiant.webp";
import asscherImg from "../../../Assets/images/home/diamond-shapes-img/Asscher.webp";
import marquiseImg from "../../../Assets/images/home/diamond-shapes-img/Marquise.webp";
import heartImg from "../../../Assets/images/home/diamond-shapes-img/Heart.webp";
import pearImg from "../../../Assets/images/home/diamond-shapes-img/Pear.webp";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
import Container from './Container';
import { Link } from 'react-router-dom/cjs/react-router-dom';
function ShopDiamondShape() {
const history = useHistory();
    const settings2 = {
        dots: false,
        infinite: false,
        speed: 500,
        accessibility: true,
        arrows: false,
        slidesToShow: 10,
        slidesToScroll: 1,
        focusOnSelect: true,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,

                }
            }
        ]
    };
    const shopbyshape = (value) =>{
        const data1 = {
            shape: value,
        } 
        sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
        history.push('/naturaldiamonds');
        window.scrollTo(0, 0);
    }

    return (
        <>
            <section className="rcs_ring_style_section">
                <Container className="rcs_custom_home_container">
                    <div className="rcs_shop_by_diamond_title text-center mb-4" data-aos="fade-up">
                        <h4>shop diamonds by shape</h4>
                        <p className='w-50 mx-auto'>
                        Explore our stunning collection of loose diamonds in Chicago. 
                        They are available in various shapes and carat sizes, so you can 
                        find the perfect stone to match your style and preferences.
                            </p>
                    </div>
                    <div className="rcs_customer_review_slider pt-3" data-aos="fade-up">
                        <Slider className="rcs_customer_say_inner rcs_shop_diamond_slider" {...settings2}  >
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Round">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <img className="rcs_shop_by_diamond_box_img_hover" src={roundImg} alt="Round" />
                                        </div>
                                        <h3>Round</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Princess">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={princessImg} alt="Princess"/>
                                        </div>
                                        <h3>Princess</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Cushion">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={cushionImg} alt="Cushion"/>
                                        </div>
                                        <h3>Cushion</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Emerald">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={emeraldImg} alt="Emerald"/>
                                        </div>
                                        <h3>Emerald</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Oval">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={ovalImg} alt="Oval"/>
                                        </div>
                                        <h3>Oval</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Radiant">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={radiantImg} alt="Radiant"/>
                                        </div>
                                        <h3>Radiant</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Asscher">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={asscherImg} alt="Asscher"/>
                                        </div>
                                        <h3>Asscher</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Marquise">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={marquiseImg} alt="Marquise"/>
                                        </div>
                                        <h3>Marquise</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Heart">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={heartImg} alt="Heart"/>
                                        </div>
                                        <h3>Heart</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className="rcs_shop_by_diamond_box_main text-center">
                                <div className="rcs_shop_by_diamond_box_img">
                                    <Link to="/naturaldiamonds/shape=Pear">
                                        <div className="rcs_shop_by_diamond_box_img_size">
                                            <Image className="rcs_shop_by_diamond_box_img_hover" src={pearImg} alt="Heart"/>
                                        </div>
                                        <h3>Pear</h3>
                                    </Link>
                                </div>
                            </div>
                            
                        </Slider>
                    </div>
                       
                </Container>
            </section>
        </>
    );
}


export default ShopDiamondShape;
