import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/Assets/css/globle.css";
import './Containers/Front/showcase_2.o/Assets/global.css'
import "./App.css";
import "../src/Assets/css/custome.css";
import "../src/Assets/css/color.css";
import "../src/Assets/css/Education/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Home from "./Containers/Front/showcase_2.o/Home";
import Home from "./Containers/Front/European/Home";
import React from "react";
import { Suspense } from "react";
import { Skeleton } from "@mui/material";
import { useState } from "react";
import MainRequest from "./Components/mainRequest";
const Mainrouter = React.lazy(() => import("./mainrouter"));
const MegaMenu = React.lazy(() => import("./Containers/Front/European/Header/MegaMenu"));
const Footer = React.lazy(() => import("./Containers/Front/European/Footer/footer"));
const App = () => {

  return (
    <>
      <BrowserRouter>
        <MainRequest />
        <Suspense
          fallback={
            <>
              <Skeltonheader />
            </>
          }
        >
          {" "}
          <MegaMenu />
        </Suspense>
        <div className="mainPage">
          <Switch>
            <Route exact path="/" component={Home} />
            <Suspense
              fallback={
                <>
                  <Skelton />
                </>
              }
            >
              <Route path="*" exact={true} component={Mainrouter} />
            </Suspense>
          </Switch>
        </div>

        <Suspense
          fallback={
            <>
              <Skelton />
            </>
          }
        >
          <Footer />
        </Suspense>
      </BrowserRouter>
    </>
  );
};

// skelton conponent
const Skelton = () => {
  return (
    <>
      <div className="sk" style={{ margin: "15px 0px" }}>
        <Skeleton variant="text" animation="wave" />
      </div>
    </>
  );
};
const Skeltonheader = () => {
  return (
    <>
      <div className="skh" style={{ margin: "0px 0px 10px 0px" }}>
        <Skeleton style={{backgroundColor:"#83272933"}} variant="text" animation="wave" />
      </div>
    </>
  );
};
export default App;
